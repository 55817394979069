import React from 'react';
import { extendObservable } from 'mobx';
import { Avatar, Icon } from 'antd';
import countryCodeEmoji from 'country-code-emoji';
import numeral from 'numeral';
import _ from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import urlRegexSafe from 'url-regex-safe';

export function hasLoader (loaderName) {
  return function decorator (target, name, descriptor) {
    const method = descriptor.value;
    const property = loaderName || name;

    if (typeof method === 'function') {
      extendObservable(target, {
        [`${property}Status`]: {
          isLoading: null,
          error: null
        }
      });
      descriptor.value = async function (...args) {
        try {
          target[`${property}Status`].isLoading = true;
          const result = await method.apply(this, args);
          target[`${property}Status`].isLoading = false;
          return result;
        } catch (e) {
          target[`${property}Status`].isLoading = false;
          target[`${property}Status`].error = e;
          console.log(`Error: ${e}`);
          throw e;
        }
      };
    }

    return descriptor;
  };
}

export const flagForCountryCode = code => (
  code === 'AN' ? countryCodeEmoji('NL') : countryCodeEmoji(code)
);

export const randomString = () => (
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15)
);

export const getUTMTags = source => {
  if (source.websiteUrl) {
    const utmTags = Object.keys(source).filter(key => key.includes('utm_'))
    if (utmTags.length > 0) {
      return (<>
        {utmTags.map(key => <div key={key}><strong>{key}</strong>: {source[key]}</div>)}
      </>)
    }
  }
  return null;
};

export const formatForCurrency = ({ amount, currency }) => {
  const formattedAmount = numeral(amount).divide(100).format('0,0[.]00');
  return `${getSymbolFromCurrency(currency) || ''}${formattedAmount} ${currency}`;
};

export const formatSingleLineItem = item => {
  let price = formatForCurrency({ amount: item.amount, currency: item.currency });
  if (item.receiveAmount && item.receiveCurrency) {
    price = formatForCurrency({ amount: item.receiveAmount, currency: item.receiveCurrency });
  }
  if (!item.quantity || item.quantity === 1) {
    return `${item.brandName} - ${price}`;
  } else {
    return `${item.brandName} - ${item.quantity} x ${price}`;
  }
};

export const getConsolidatedLineItems = lineItems => {
  let consolidatedItems = [];

  const getItemError = item => {
    if (item.product) {
      if (item.product.brand.discontinued || item.product.brand.thirdPartyDisabled) {
        return 'brand-unavailable';
      } else if (item.product.discontinued) {
        return 'product-unavailable';
      } else if (isPriceOutOfRange(item)) {
        return 'price-out-of-range';
      }
    }
    return null;
  };

  lineItems.forEach(item => {
    const index = _.findIndex(consolidatedItems, { brandCode: item.brandCode, amount: item.amount, currency: item.currency });
    const consolidatedItem = consolidatedItems[index];

    if (index !== -1) {
      consolidatedItem.quantity = consolidatedItem.quantity + 1;
      // Using an || operator here to avoid overwriting an existing error with `null`
      consolidatedItem.error = consolidatedItem.error || getItemError(item);
      // Using the && operator here so that if any line item is not fulfilled, the consolidated one won't be either
      consolidatedItem.fulfilledAt = consolidatedItem.fulfilledAt && item.fulfilledAt;
    } else {
      const newItem = _.clone(item);
      newItem.quantity = 1;
      newItem.error = getItemError(item);

      consolidatedItems.push(newItem);
    }
  });

  return consolidatedItems;
};

export const calculateProfitForOrder = (order, markups) => {
  const cashbackCurrencies = ['bcad', 'busd', 'bbmd'];
  const markup = numeral(markups[order.paymentCurrency] || 1).subtract(1).multiply(100).value();
  const markupEarnings = numeral(markup).divide(100).multiply(order.totalInCents).divide(100).value();

  let discountEarnings = order.lineItems.filter(li => !!li.product)
    .map(li => {
      let discount = 0;
      if (li.product.brand.discountPercent) {
        discount = li.product.brand.discountPercent;
      } else if (li.product.brand.provider.name === 'ding') {
        discount = 5;
      }
      return numeral(discount).divide(100).multiply(li.amount).divide(100).value();
    })
    .reduce((acc, curr) => acc + curr, 0);
  let ourTakeOfDiscountEarnings = discountEarnings;
  if (discountEarnings > 0) {
    if (cashbackCurrencies.includes(order.paymentCurrency)) {
      ourTakeOfDiscountEarnings = discountEarnings * 0.75;
    } else if (order.referredBy && order.referredBy.active && order.referredBy.newReferralPercent > 0) {
      const ourPercent = (1 - (order.referredBy.newReferralPercent / 100));
      ourTakeOfDiscountEarnings = discountEarnings * ourPercent;
    }
  }
  const cardFees = order.lineItems.filter(li => li.product && li.product.brand.provider.name === 'globetopper').length * 0.2;
  const totalEarnings = numeral(markupEarnings).add(ourTakeOfDiscountEarnings).subtract(cardFees).value();
  const percent = numeral(totalEarnings).multiply(100).divide(order.totalInCents).multiply(100).format('0[.]00');

  return { totalEarnings, percent, orderTotal: numeral(order.totalInCents).divide(100).value() };
};

export const formattedProfitForOrder = (order, markups) => {
  try {
    const { totalEarnings, percent } = calculateProfitForOrder(order, markups);
    return numeral(totalEarnings).format('$0[.]00') + ` (${percent}%)`;
  } catch (e) {
    console.log(e);
    return '?';
  }
};

export const copyToClipboard = value => {
  const input = document.createElement('input');

  input.value = value;
  input.style.position = 'absolute';
  input.style.zIndex = -1;
  input.setAttribute('readonly', '');
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(input.value);
  document.body.removeChild(input);
};

export const markupsForBrand = brand => {
  if (!brand) return;

  const markups = { percent: [], fixed: [] };

  brand.products.filter(p => !p.discontinued && p.enabled && p.metadata && p.metadata.fees).map(product => {
    const discountPercent = numeral(product.metadata.discount).value();
    const upchargePercent = discountPercent < 0 ? -discountPercent : 0;
    const percentFees = product.metadata.fees.filter(f => numeral(f.fee_per).value() !== 0);
    const fixedFees = product.metadata.fees.filter(f => numeral(f.fee_dec).value() !== 0.2 && numeral(f.fee_dec).value() !== 0);
    let markup = 0;
    if (percentFees.length > 0) {
      markup = _.chain(percentFees).filter(f => numeral(f.fee_per).value !== 0).map(f => numeral(f.fee_per).value()).reduce((i, f) => {
        return i + f;
      }, 0).value();
      markups.percent.push(markup);
    }
    fixedFees.forEach(f => {
      markups.fixed.push(numeral(f.fee_dec).value());
    });

    if (upchargePercent) {
      markups.percent.push(upchargePercent);
    }
  });

  markups.percent = _.uniq(markups.percent);
  markups.fixed = _.uniq(markups.fixed);

  return markups;
};

export const isPriceOutOfRange = (lineItem) => {
  if (lineItem.product.variablePrice) {
    let lineItemAmount = numeral(lineItem.amount).value();
    if (lineItem.receiveAmount) {
      lineItemAmount = numeral(lineItem.receiveAmount).value()
    }
    if (lineItemAmount > numeral(lineItem.product.maxPriceInCents).value() || lineItemAmount < numeral(lineItem.product.minPriceInCents).value()) {
      return true;
    }
  }
  return false;
};

export const splitProducts = (lineItem, brand) => {
  let remainder = +lineItem.receiveAmount;
  const receiveAmount = +lineItem.receiveAmount;
  const liAmount = +lineItem.amount;
  const replacements = [];

  const availableProducts = brand.products.filter(product => (
    product.enabled && !product.discontinued &&
    product.id !== lineItem.product.id && product.currency === lineItem.receiveCurrency
  )).sort((a, b) => {
    if (a.allowedPricesInCents[0] > b.allowedPricesInCents[0]) {
      return -1;
    }

    if (a.allowedPricesInCents[0] < b.allowedPricesInCents[0]) {
      return 1;
    }

    return 0;
  });

  for (let i = 0; i < availableProducts.length; i++) {
    const replacement = availableProducts[i];
    const li = _.clone(lineItem);

    while (replacement.allowedPricesInCents[0] <= remainder) {
      replacements.push(
        _.merge(
          _.pick(li, ['currency', 'receiveCurrency', 'brandCode', 'brandName', 'productId', 'orderId']),
          {
            amount: `${Math.round(replacement.allowedPricesInCents[0] / receiveAmount * liAmount)}`,
            receiveAmount: `${replacement.allowedPricesInCents[0]}`,
            productId: replacement.id
          }
        )
      );

      remainder -= replacement.allowedPricesInCents[0];
    }
  }

  return { replacements, remainder };
};

export const splitAllowedPriceInCents = (lineItem, product) => {
  let remainder = +lineItem.receiveAmount;
  const receiveAmount = +lineItem.receiveAmount;
  const liAmount = +lineItem.amount;
  const availableDenominations = product.allowedPricesInCents
    .filter(price => price <= remainder)
    .sort((a, b) => b - a);

  // Initialize dp array with a large number representing 'Infinity'
  const dp = Array(remainder + 1).fill(Number.MAX_SAFE_INTEGER);
  const lastUsed = Array(remainder + 1).fill(-1);

  dp[0] = 0;

  for (let i = 0; i <= remainder; i++) {
    for (const denom of availableDenominations) {
      if (i + denom <= remainder && dp[i] + 1 < dp[i + denom]) {
        dp[i + denom] = dp[i] + 1;
        lastUsed[i + denom] = denom;
      }
    }
  }

  if (dp[remainder] === Number.MAX_SAFE_INTEGER) {
    return { replacements: [], remainder }; // Unable to split the amount
  }

  const replacements = [];
  let remainingAmount = remainder;

  while (remainingAmount > 0) {
    const denom = lastUsed[remainingAmount];
    if (denom === -1) {
      break;
    }
    const li = _.clone(lineItem);
    replacements.push(
      {
        amount: `${Math.round(denom / receiveAmount * liAmount)}`,
        receiveAmount: `${denom}`,
        brandCode: product.providerSku,
        brandName: li.brandName,
        productId: product.id,
        currency: product.currency,
        receiveCurrency: product.currency,
        orderId: li.orderId
      }
    );
    remainingAmount -= denom;
  }
  console.log(JSON.stringify(replacements, null, 2), remainingAmount);
  return { replacements, remainder: remainingAmount };
};

export const splitMaxPriceInCents = (lineItem) => {
  const maxPriceInCents = numeral(lineItem.product.maxPriceInCents).value();
  let remainder = numeral(lineItem.receiveAmount).value();
  const receiveAmount = numeral(lineItem.receiveAmount).value();
  const liAmount = numeral(lineItem.amount).value();
  const replacements = [];

  while (remainder >= maxPriceInCents) {
    const li = _.clone(lineItem);
    replacements.push(
      _.merge(
        _.pick(li, ['currency', 'receiveCurrency', 'brandCode', 'brandName', 'productId', 'orderId']),
        {
          amount: `${Math.round(maxPriceInCents / receiveAmount * liAmount)}`,
          receiveAmount: `${maxPriceInCents}`
        }
      )
    );
    remainder -= maxPriceInCents;
  }

  return { replacements, remainder };
};

export const extractUrls = text => {
  const matches = (text || '').match(urlRegexSafe());
  return matches || [];
};

const knownRedemptionMethods = ['Instore', 'Mobile App', 'Online', 'Phone'];

export const parseRedemptionMethods = (usage = '') => {
  const formattedUsage = usage.replace('-', ' ')
    .replace(/, /ig, ',')
    .replace('Call Centre', 'Phone')
    .replace('Over the Phone', 'Phone')
    .replace(/In Store/ig, 'Instore')
    .replace(' & ', ',')
    .replace('/', ',')
    .replace('Mobile Apps', 'Mobile App');
  if (formattedUsage === '' || formattedUsage === 'Code') {
    return null;
  }
  return formattedUsage.split(',').filter(method => knownRedemptionMethods.includes(method));
}

const addSpaceAfterPeriod = text => {
  // Regular expression explained:
  // \. matches the period.
  // (?=[A-Z]) positive lookahead to check for an uppercase letter following the period.
  // (?! \.) negative lookahead to ensure not followed by a space and period (to attempt handling domains more gracefully).
  return text.replace(/(?<!www)\.+(?=[A-Z])(?!com\b)(?!\.)/g, '. ');
}

export const sanitizeField = (text = '') => {
  if (!text) {
    return undefined;
  }
  let sanitized = `${text}`.replace(/\r?\n?YouGotaGift\.com does not accept cashbacks?, refunds or returns\.?\n?/ig, '');
  sanitized = sanitized.replace(/\r?\n?This is gifting with YouGotaGift!?\n?/ig, '');
  sanitized = sanitized.replace(/Gifted\.PH will verify all orders before processing\.\s/ig, '');
  sanitized = sanitized.replace(/Both Gifted\.PH/ig, 'Both Bidali');
  sanitized = sanitized.replace('Gifted.ph ', '');
  sanitized = sanitized.replace(/_x000D_/ig, '');
  sanitized = sanitized.replace(/^Terms\s?(and|&|\/)\s?Conditions:?\n?/ig, '');
  sanitized = sanitized.replace(/^Terms of (Use|Service)\s?:?\r?\n?\s?/ig, '');
  sanitized = sanitized.replace(/^\s+/ig, '');
  sanitized = sanitized.replace(/^"/ig, '');
  sanitized = sanitized.replace(/"$/ig, '');
  sanitized = sanitized.replace(/\r?\n+$/ig, '');
  sanitized = sanitized.replace(/\\n/ig, '\n');
  sanitized = sanitized.replace(/\\r/ig, '\r');
  sanitized = sanitized.replace(/\\t/ig, '\t');
  sanitized = sanitized.replace(/http:\/\//ig, 'https://');
  sanitized = sanitized.replace(/^(\*)+(LOCAL|ENGLISH)(\*)*\s?/g, '');
  sanitized = sanitized.replace(/\sESP:/ig, '\r\nESP:');
  sanitized = sanitized.replace(/\sEN:/ig, '\r\nEN:');
  sanitized = sanitized.replace(/\sFR:/ig, '\r\nFR:');
  sanitized = sanitized.replace(/\s\**(ENGLISH|LOCAL)\**\s/ig, '\r\n\r\n');
  sanitized = sanitized.replace(/&nbsp;/ig, ' ');
  sanitized = sanitized.replace(/&amp;/ig, '&');
  sanitized = sanitized.replace(/&gt;/ig, '>');
  sanitized = addSpaceAfterPeriod(sanitized);
  return sanitized.trim() !== '' ? sanitized : undefined;
}

export const sourceLogoForOrder = order => {
  const { referredById, source } = order;
  let logoUrl = `https://assets.bidali.com/wallets/${source.appName.replace(' ', '')}.png`;
  if (referredById === '3f6d607c-3ac8-42d3-a389-e3c8ea0c141b') {
    logoUrl = 'https://assets.bidali.com/wallets/sonar.png';
  }
  return <Avatar size="small" src={logoUrl} />;
}

const getSourceIcon = osName => {
  if (!osName) {
    return '?';
  }
  if (osName.toLowerCase() === 'ios') {
    return <Icon type="apple" theme='filled' style={{ color: '#777' }} />
  } else if (osName.toLowerCase() === 'android') {
    return <Icon type="android" theme='filled' style={{ color: '#777' }} />
  } else if (osName.toLowerCase() === 'windows') {
    return <Icon type="windows" theme='filled' style={{ color: '#777' }} />
  }
  return osName;
};

export const sourceForOrder = order => {
  const { referredById, source } = order;
  let appName = source.appName;
  let osName = source.osName;

  if (referredById === '3f6d607c-3ac8-42d3-a389-e3c8ea0c141b') {
    appName = 'Sonar';
  } else if (referredById === 'a1cc9555-ee84-43d9-ac13-5a278c0b9ae0') {
    appName = 'Coinomi';
  } else if (referredById === 'bb020e6c-bd7d-46d9-a7aa-794345c193d2') {
    appName = 'TipLink';
  }
  order.source.appName = appName;

  return <div>
    {source && (appName || (source.websiteUrl && source.websiteUrl !== 'https://giftcards.bidali.com')) && <>
      <h4>{appName && <span>{sourceLogoForOrder(order)} {appName.toUpperCase()}</span>}{!appName && source.websiteUrl && source.websiteUrl} {!!osName && <>on <span>{getSourceIcon(osName)}</span></>}</h4>
    </>}
    {source && !!source.gclid && <div><img src="/google-ads.png" width="24" height="24" alt="Google Ads" /> Google Ads</div>}
    {source && <div>{getUTMTags(source)}</div>}
  </div>
}
